<template>
  <div class="row mt-3">
    <div class="col-12">
      <div class="form-row">
        <sgv-input-radio
          class="col-12"
          inline
          :options="options"
          select="value"
          v-model="selected">
          <template slot-scope="option">
            {{option.text}}
          </template>
        </sgv-input-radio>
      </div>
    </div>

    <CouponEvent 
      class="col-12"
      v-show="selected === 'CouponTicket'"
      :templateType="templateType"
      :docType="docType"
      :docId="docId">
    </CouponEvent>

    <CouponTicket 
      class="col-12"
      v-show="selected === 'CouponTicket'"
      :templateType="templateType"
      :docType="docType"
      :docId="docId">
    </CouponTicket>

    <CouponTicketActivated 
      class="col-12"
      v-show="selected === 'CouponTicketActivated'"
      :templateType="templateType"
      :docType="docType"
      :docId="docId"
      :contactId="contactId"
      @tickets="isTicketActivated">
    </CouponTicketActivated>
  </div>
</template>

<script>
import CouponEvent from './CouponEvent.vue'
import CouponTicket from './CouponTicket.vue'
import CouponTicketActivated from './CouponTicketActivated.vue'


export default {
  props: {
    templateType: {
      type: String,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    docId: {
      type: Number,
      required: true
    },
    contactId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      options: [
        {text: 'โปรโมชั่น', value: 'CouponTicket'},
        {text: 'รอการจัดส่ง', value: 'CouponTicketActivated'},
      ],
      selected: 'CouponTicket'
    }
  },
  methods: {
    isTicketActivated (arr) {
      this.$emit('tickets', arr)
    }
  },
  components: {
    CouponEvent,
    CouponTicket,
    CouponTicketActivated
  }
}
</script>

<style lang="css" scoped>
</style>
