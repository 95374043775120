<template>
  <div class="mt-4">
    <sgv-table
      :rKey="rKey"
      :items="ticketsSorted"
      :headers="headers"
      :filter.sync="filter"
      :options.sync="options"
      :rowsPerPage="rowsPerPage">

      <template slot-scope="{item, hidden}">
        <tr>
          <td v-if="hidden.coupon">
            <small>
              <div>{{ item.id }}</div>
              <div>
                {{ item.coupon.code }}
                <span 
                  v-if="!item.activatedAt" 
                  class="text-success pointer" 
                  @click="getLink(item)">
                  คัดลอก Link
                </span>
              </div>
              <div class="text-primary">{{ item.coupon.name }}</div>
            </small>
          </td>
          <td v-if="hidden.name" style="white-space: nowrap;">
            {{item.name}}
            <small>
              <div
                class="text-primary pre-line"
                v-if="item.inventory">
                {{ item.inventory.code }} ({{ item.inventory.name }}) {{ item.inventory.unit.name }}
              </div>
            </small>
          </td>
          <td v-if="hidden.createdAt">
            <span>{{ item.createdAt | date}}</span><br>
            <small class="text-primary">{{item.createdUser.name}}</small>
          </td>
          <td v-if="hidden.approvedAt">
            <span v-if="item.approvedAt">
              <span class="text-warning">{{item.approvedAt | date}}</span><br>
              <small class="text-primary">{{item.approvedUser.name}}</small>
            </span>
          </td>
          <td v-if="hidden.activatedAt">
            <span class="text-info">{{ item.activatedAt | date }}</span>
          </td>
          <td v-if="hidden.closedAt">
            <span v-if="item.closedAt">
              <span class="text-success">{{item.closedAt | date}}</span><br>
              <small class="text-primary">{{item.closedUser.name}}</small>
            </span>
          </td>
          <td v-if="hidden.action">
            <button
              type="button"
              v-if="!item.activatedAt && !item.coupon.closedAt" 
              class="btn btn-danger" 
              @dblclick="cancelCouponTicket(item.id)">
              ยกเลิก
            </button>
            <button
              type="button"
              v-if="item.closedAt && !item.coupon.closedAt" 
              class="btn btn-danger" 
              @dblclick="rollbackCouponTicket(item.id)">
              ยกเลิกจัดส่ง
            </button>
          </td>
        </tr>
      </template>
    </sgv-table>
  </div>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import { 
  LIST_COUPON_TICKET,
  CANCEL_COUPON_TICKET,
  ROLLBACK_COUPON_TICKET,
  WATCH_COUPON_TICKET_CREATED,
  WATCH_COUPON_TICKET_DESTROYED,
  WATCH_COUPON_TICKET_UPDATED
} from '../graph/coupon'

export default {
  mixins: [retainMixin],
  props: {
    templateType: {
      type: String,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    docId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      rKey: `Doc${this.$form.capitalize(this.docType)}CouponTicket`,
      headers: [
        {text: 'กิจกรรม', value: 'coupon'},
        {text: 'รางวัล', value: 'name'},
        {text: 'วันที่สร้าง', value: 'createdAt'},
        {text: 'วันที่อนุมัติ', value: 'approvedAt'},
        {text: 'วันที่สุ่ม', value: 'activatedAt'},
        {text: 'วันที่จัดส่ง', value: 'closedAt'},
        { text: 'Action', value: 'action' },
      ],
      filter: {
        limit: null,
        offset: 0,
        order: null,
        params: null
      },
      options: {
        headers: ['coupon', 'name'],
        column: null,
        search: null,
        toolbar: null,
      },
      rFields: ['options'],
      rowsPerPage: [{ text: 'ทั้งหมด', value: null }],
      tickets: []
    }
  },
  computed: {
    ticketsSorted () {
      return [...this.tickets].sort((a, b) => new Date(b.approvedAt) - new Date(a.approvedAt))
    }
  },
  apollo: {
    tickets: {
      query() {
        return LIST_COUPON_TICKET(this.templateType)
      },
      variables() {
        return {
          docType: this.docType,
          docId: this.docId
        }
      },
      fetchPolicy: 'network-only',
      subscribeToMore: [
        {
          document() {
            return WATCH_COUPON_TICKET_CREATED(this.templateType)
          },
          variables() {
            return { 
              docType: this.docType,
              docId: this.docId
            }
          },
          updateQuery(previous, { subscriptionData }) {
            const newTickets = [
              subscriptionData.data.ticketCreated,
              ...previous.tickets
            ]
            const result = {
              ...previous,
              tickets: newTickets
            }
            return result
          }
        },
        {
          document() {
            return WATCH_COUPON_TICKET_UPDATED(this.templateType)
          },
          variables() {
            return {
              docType: this.docType,
              docId: this.docId
            }
          },
          updateQuery(previous, { subscriptionData }) {
            const ticketIndex = previous.tickets.findIndex(v => v.id === subscriptionData.data.ticketUpdated.id)
            const newTickets = [...previous.tickets]
            newTickets[ticketIndex] = subscriptionData.data.ticketUpdated

            const result = {
              ...previous,
              tickets: newTickets
            }
            return result
          }
        },
        {
          document() {
            return WATCH_COUPON_TICKET_DESTROYED(this.templateType)
          },
          variables() {
            return { 
              docType: this.docType,
              docId: this.docId
            }
          },
          updateQuery(previous, { subscriptionData }) {
            const ticketIndex = previous.tickets.findIndex(v => v.id === subscriptionData.data.ticketDestroyed.id)
            if (ticketIndex === -1) return previous
            const newTickets = [...previous.tickets]
            newTickets.splice(ticketIndex, 1)
            const result = {
              ...previous,
              tickets: newTickets
            }
            return result
          }
        }
      ]
    },
  },
  methods: {
    getLink(ticket) {
      const url = ticket?.coupon.url
      if (!url) {
        this.$toasted.global.error('ไม่พบการตั้งค่า URL')
        return
      }
      const link = url
        .replace(/:couponType/g, ticket.coupon?.type || '')
        .replace(/:couponId/g, ticket.couponId)
        .replace(/:couponCode/g, ticket.coupon?.code || '')
        .replace(/:couponTicketId/g, ticket.id)

      this.$copyText(link)
      this.$toasted.global.success('คัดลอก Link สำเร็จ')
    },
    cancelCouponTicket(couponTicketId) {
      this.$apollo.mutate({
        mutation: CANCEL_COUPON_TICKET(this.templateType),
        variables: {
          docType: this.docType,
          docId: this.docId,
          couponTicketId
        }
      }).then(() => {
        this.$toasted.global.success('ยกเลิกคูปองสำเร็จ')
      }).catch(this.$toasted.global.error)
    },
    rollbackCouponTicket(couponTicketId) {
      this.$apollo.mutate({
        mutation: ROLLBACK_COUPON_TICKET(this.templateType),
        variables: {
          docType: this.docType,
          docId: this.docId,
          couponTicketId
        }
      }).then(() => {
        this.$toasted.global.success('ยกเลิกจัดส่งสำเร็จ')
      }).catch(this.$toasted.global.error)
    }
  },
  watch: {
    options: 'setRetaining'
  }
}
</script>

<style lang="css" scoped>
</style>
