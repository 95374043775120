<template>
  <div>
    <h5 v-if="coupons.length === 0" class="text-center">ไม่พบข้อมูลโปรโมชั่น</h5>
    <template v-else>
      <div 
        v-for="coupon in coupons"
        :key="coupon.id">
        <h4 class="text-center">{{ coupon.code }} ({{ coupon.name }})</h4>
        <p>{{ coupon.description }}</p>
        <button 
          type="button" 
          class="btn btn-success"
          @click="approveCoupon(coupon.id)">
          สร้างคูปอง
        </button>
      </div>
    </template>
  </div>
</template>

<script>
import { 
  LIST_COUPON,
  APPROVE_COUPON_TICKET
} from '../graph/coupon'

export default {
  props: {
    templateType: {
      type: String,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    docId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      coupons: []
    }
  },
  apollo: {
    coupons: {
      query() {
        return LIST_COUPON(this.templateType)
      },
      variables() {
        return {
          docType: this.docType
        }
      },
      fetchPolicy: 'network-only'
    },
  },
  methods: {
    approveCoupon (couponId) {
      this.$apollo.mutate({
        mutation: APPROVE_COUPON_TICKET(this.templateType),
        variables: {
          docType: this.docType,
          docId: this.docId,
          couponId
        }
      }).then(() => {
        this.$toasted.global.success('สร้างคูปองสำเร็จ')
      }).catch(this.$toasted.global.error)
    }
  }
}
</script>

<style lang="css" scoped>
</style>
