import gql from 'graphql-tag'


export const LIST_COUPON = (templateType) => gql`query LIST_COUPON ($docType: String!) {
  coupons: listDoc${templateType}Coupon (docType: $docType) {
    id type code name description
    startedAt expiredAt
  }
}`

export const LIST_COUPON_TICKET = (templateType) => gql`query LIST_COUPON_TICKET ($docType: String!, $docId: Int!) {
  tickets: listDoc${templateType}CouponTicket (docType: $docType, docId: $docId) {
    id type name 
    couponId coupon {id type code name url closedAt}
    inventoryId inventory {
      id type code name 
      unitId unit {id name}
    }
    createdAt createdBy createdUser {id name}
    approvedAt approvedBy approvedUser {id name}
    activatedAt
    closedAt closedBy closedUser {id name}
  }
}`

export const LIST_COUPON_TICKET_ACTIVATED = (templateType) => gql`query LIST_COUPON_TICKET_ACTIVATED ($docType: String!, $contactId: Int!) {
  tickets: listDoc${templateType}CouponTicketActivated (docType: $docType, contactId: $contactId) {
    id type name 
    couponId coupon {id type code name url closedAt}
    inventoryId inventory {
      id type code name 
      unitId unit {id name}
    }
    createdAt createdBy createdUser {id name}
    approvedAt approvedBy approvedUser {id name}
    activatedAt
    closedAt closedBy closedUser {id name}
  }
}`

export const WATCH_COUPON_TICKET_CREATED = (templateType) => gql`subscription WATCH_COUPON_TICKET_CREATED ($docType: String!, $docId: Int!) {
  ticketCreated: watchDoc${templateType}CouponTicketCreated (docType: $docType, docId: $docId) {
    id type name 
    couponId coupon {id type code name url closedAt}
    inventoryId inventory {
      id type code name 
      unitId unit {id name}
    }
    createdAt createdBy createdUser {id name}
    approvedAt approvedBy approvedUser {id name}
    activatedAt
    closedAt closedBy closedUser {id name}
  }
}`

export const WATCH_COUPON_TICKET_UPDATED = (templateType) => gql`subscription WATCH_COUPON_TICKET_UPDATED ($docType: String!, $docId: Int!) {
  ticketUpdated: watchDoc${templateType}CouponTicketUpdated (docType: $docType, docId: $docId) {
    id type name 
    couponId coupon {id type code name url closedAt}
    inventoryId inventory {
      id type code name 
      unitId unit {id name}
    }
    createdAt createdBy createdUser {id name}
    approvedAt approvedBy approvedUser {id name}
    activatedAt
    closedAt closedBy closedUser {id name}
  }
}`

export const WATCH_COUPON_TICKET_DESTROYED = (templateType) => gql`subscription WATCH_COUPON_TICKET_DESTROYED ($docType: String!, $docId: Int!) {
  ticketDestroyed: watchDoc${templateType}CouponTicketDestroyed (docType: $docType, docId: $docId) {
    id
  }
}`

export const APPROVE_COUPON_TICKET = (templateType) => gql`mutation APPROVE_COUPON_TICKET ($docType: String!, $docId: Int!, $couponId: ID!) {
  approveCoupon: approveDoc${templateType}CouponTicket (docType: $docType, docId: $docId, couponId: $couponId) {
    id
  }
}`

export const CANCEL_COUPON_TICKET = (templateType) => gql`mutation CANCEL_COUPON_TICKET ($docType: String!, $docId: Int!, $couponTicketId: ID!) {
  cancelCoupon: cancelDoc${templateType}CouponTicket (docType: $docType, docId: $docId, couponTicketId: $couponTicketId) {
    id
  }
}`

export const CLOSE_COUPON_TICKET = (templateType) => gql`mutation CLOSE_COUPON_TICKET ($docType: String!, $docId: Int!, $couponTicketId: ID!) {
  closeCoupon: closeDoc${templateType}CouponTicket (docType: $docType, docId: $docId, couponTicketId: $couponTicketId) {
    id
  }
}`

export const ROLLBACK_COUPON_TICKET = (templateType) => gql`mutation ROLLBACK_COUPON_TICKET ($docType: String!, $docId: Int!, $couponTicketId: ID!) {
  rollbackCoupon: rollbackDoc${templateType}CouponTicket (docType: $docType, docId: $docId, couponTicketId: $couponTicketId) {
    id
  }
}`
